/* global showdown, firebase, typeset, MathJax, AmbientLightSensor */

// IDEA: Filter Funktion

showdown.setFlavor('github');
const converter = new showdown.Converter({
  tables: true,
  tasklists: true,
  smoothLivePreview: true,
  simpleLineBreaks: true,
  underline: true,
  emoji: true,
});

// firebase
let data;
let sets = [];
let defaultSet = '';
let editCard;

function loadSets() { // eslint-disable-line no-unused-vars
  firebase
      .database()
      .ref(`users/${firebase.auth().getUid()}/defaultSet`)
      .once('value', snapshot => {
        defaultSet = snapshot.val();
        firebase.database().ref(`users/${firebase.auth().getUid()}/sets`)
            .once('value', snapshot => {
              const d = snapshot.val() || {};
              sets = [];
              sets = Object.keys(d).map(key => {
                d[key].key = key;
                return d[key];
              });

              showSets();
            });
      });
}

function showSets() {
  $('.dropdown-menu').empty();

  const defSet = sets.find(set => set.key == defaultSet) || (sets.length > 0 ? sets[0] : null);
  $('#navbarDropdown').text(defSet ? (defSet.title || 'Ohne Titel') : 'Kein Set verfügbar');
  if (defSet) listenData(defSet.key);

  const setHtml = sets.map(set => `<a class="dropdown-item setLink" href="#" setId="${set.key}">${set.title}</a>`).join('');
  if (setHtml != '') $('.dropdown-menu').append(setHtml);

  $('.dropdown-menu').append('<a class="dropdown-item setLink text-success" id="neuesSet" href="#" '
    + 'style="border-top: 1px solid #777;padding-top: 12px;">Neues Set</a>');

  if (defSet) $('.dropdown-menu').append('<a class="dropdown-item setLink text-danger" id="deleteSet" href="#" style="">Lösche Set</a>');

  $('.setLink').unbind('click').on('click', function () {
    const id = $(this).attr('setId');
    if (id) {
      listenData(id);
      firebase.database().ref(`users/${firebase.auth().getUid()}/defaultSet`).set(id);
    } else if ($(this).is('#neuesSet')) {
      const name = prompt('Name des Sets');
      if (!name || name == '') return;
      firebase.database().ref(`users/${firebase.auth().getUid()}/sets`).push({ title: name })
          .then(data => {
            const key = data.key;
            console.log(`Neues Set mit id: ${key}`);
            sets.push({ key, title: name });
            listenData(key);
            firebase.database().ref(`users/${firebase.auth().getUid()}/defaultSet`).set(key);
            defaultSet = key;
            showSets();
          })
          .catch(err => alert(err.message));
    } else if ($(this).is('#deleteSet')) {
      const set = sets.find(s => s.key == defaultSet);
      if (set == null) return;
      const conformation = prompt(`Soll das Set "${set.title || defaultSet}" wirklich gelöscht werden? [y/n]`);
      if (conformation.toLowerCase() == 'y') {
        firebase.database().ref(`users/${firebase.auth().getUid()}/sets/${set.key}`).remove()
            .then(() => {
              sets = sets.filter(s => s.key != set.key);
              console.log('Set erfolgreich gelöscht.');
              showSets();
            })
            .catch(console.error);
      }
    }
  });
  showColorMode();
}

let firebaseRef;

function listenData(setId) {
  if (firebaseRef && !setId) firebaseRef.off();
  if (setId) {
    if (firebaseRef) firebaseRef.off();
    firebaseRef = firebase.database().ref(`users/${firebase.auth().getUid()}/sets/${setId}/cards`);
    firebaseRef.on('value', snapshot => {
      if (checkIfDataChanged(snapshot.val())) {
        console.log('data changed');
        data = snapshot.val();
        showListeAbfrage(setId);
      }
      data = snapshot.val();
    });
  }
}

function checkIfDataChanged(newData) {
  if (!data || jQuery.isEmptyObject(data) || !newData || jQuery.isEmptyObject(newData)) return true;
  const keys = Object.keys(newData).concat(Object.keys(data));
  for (const key of keys) {
    if (data[key] == undefined || newData[key] == undefined) return true;
    if (data[key].title != newData[key].title) return true;
    if (data[key].type != newData[key].type) return true;
    if (data[key].answers.join('§$%') != newData[key].answers.join('§$%')) return true;
    if (data[key].status != newData[key].status) {
      let c = '#000';
      if (newData[key].status == 'perfect') c = '#79d375';
      if (newData[key].status == 'medium') c = '#f8d154';
      if (newData[key].status == 'bad') c = '#e9464a';

      $('#abfrage').children(`[cardid=${key}]`).find('.dot').css('background-color', c);
      $('#liste').children(`[cardid=${key}]`).find('.dot').css('background-color', c);
    }
  }

  return false;
}

function showListeAbfrage(setId) {
  if (setId) {
    $('#navbarDropdown').text(sets.reduce((p, c) => {
      if (c.key == setId) return p + c.title;
      return p;
    }, ''));
  }
  const id = $('.navbar').find('.active').find('.nav-link').attr('id');

  if (id == 'listeLink') {
    showListe();
  } else if (id == 'abfrageLink') {
    showAbfrage();
  } else {
    showHinzufuegen();
  }
  showColorMode();
  editCard = null;
}

function showHinzufuegen() {
  const editID = editCard; editCard = null;
  $('#abfrage').hide();
  $('#liste').hide();

  $('.otherAnswers').remove();
  $('#titleInput, #verweisInput, .antwortTextarea').val('');
  $('#typeSelect').val('definition');
  $('#deleteCard').hide();

  if (editID) {
    const card = data[editID];
    $('#titleInput').val(card.title);
    $('#typeSelect').val(card.type);
    $('#verweisInput').val(card.verweis);

    for (let i = 1; i < card.answers.length; i++) {
      $('.antwortTextarea').last().parent('.form-group')
          .after('<div class="form-group otherAnswers"><label for="antwortTextarea">Antwort</label>'
          + '<textarea class="form-control antwortTextarea" rows="3"></textarea></div>');
    }

    for (let i = 0; i < card.answers.length; i++) {
      const ans = card.answers[i];
      $($('.antwortTextarea').toArray()[i]).val(ans);
    }

    $('#deleteCard').unbind('click').on('click', () => {
      if (prompt('Soll die aktuelle Karte gelöscht werden? [y/n]').toLowerCase() == 'y') {
        firebaseRef.child(editID).remove()
            .then(() => console.log('Karte gelöscht'))
            .catch(err => console.error(err));
      }
    });
    $('#deleteCard').show();
  }

  $('.preview').empty();
  let timeout;

  const listenerPreview = () => {
    $('#addCard select').unbind('change').on('change', () => { $('#addCard input').trigger('keyup'); });
    $('#addCard input, #addCard textarea').unbind('keyup').on('keyup', () => {
      clearTimeout(timeout);
      timeout = setTimeout(reloadMathJax, 1000);
      $('.preview').empty();
      const title = $('#titleInput').val().trim();
      const verweis = $('#verweisInput').val().trim();
      const antworten = $('.antwortTextarea').toArray()
          .map(ele => $(ele).val().trim())
          .map(ans => converter.makeHtml(escapeString(ans)))
          .filter(text => text != '');

      if (title == '' && verweis == '' && antworten.length <= 0) return;
      $('.preview').append(getListCardHtml(
          'preview_id',
          title,
          $('#typeSelect').val(),
          verweis,
          'bad',
          antworten,
          false,
      ));
      showColorMode();
    });

    $('#addCard input, #addCard textarea').unbind('change').on('change', () => {
      clearTimeout(timeout);
      reloadMathJax();
    });
  };

  const dropEvent = function (event) {
    console.log('image upload');
    event.preventDefault();
    event.stopPropagation();

    let files = [...event.originalEvent.dataTransfer.files];
    files = files.filter(file => file.type.indexOf('image') >= 0);
    if (files.length > 0) {
      const file = files[0];
      firebase.storage().ref(`images/${new Date().getTime()}${file.name}`).put(file, {
        contentType: file.type,
      })
          .then(snapshot => snapshot.ref.getDownloadURL())
          .then(url => $(this).first().val((i, text) => `${text}![markdown](${url} "markdown")`))
          .catch(err => console.error(err));
    }
  };

  $('#addAns').unbind('click').on('click', () => {
    $('.antwortTextarea').last().parent('.form-group')
        .after('<div class="form-group otherAnswers"><label for="antwortTextarea">Antwort</label>'
        + '<textarea class="form-control antwortTextarea" rows="3"></textarea></div>');
    $('html, .antwortTextarea').unbind('dragover dragleave drop').on('dragover dragleave drop', event => {
      event.preventDefault();
      event.stopPropagation();
    });
    $('.antwortTextarea').unbind('drop').on('drop', dropEvent);
    listenerPreview();
  });

  $('html, .antwortTextarea').unbind('dragover dragleave drop').on('dragover dragleave drop', event => {
    event.preventDefault();
    event.stopPropagation();
  });

  $('.antwortTextarea').unbind('drop').on('drop', dropEvent);

  $('#saveCard').unbind('click').on('click', () => {
    const title = $('#titleInput').val().trim();
    const type = $('#typeSelect').val();
    const verweis = $('#verweisInput').val().trim();
    const antworten = $('.antwortTextarea').toArray().map(ele => escapeString($(ele).val().trim())).filter(text => text != '');

    let valid = true;
    if (title == '') {
      $('#titleInput').addClass('is-invalid');
      $('#titleInput').unbind('change').on('change', () => {
        $('#titleInput').removeClass('is-invalid');
      });
      valid = false;
    }
    if (antworten.length == 0) {
      $('.antwortTextarea').addClass('is-invalid');
      $('.antwortTextarea').unbind('change').on('change', () => {
        $('.antwortTextarea').removeClass('is-invalid');
      });
      valid = false;
    }

    if (valid) {
      if (editID) {
        firebaseRef.child(editID).update({
          answers: antworten, title, type, verweis,
        }).then(() => console.log('Updated Karte!'))
            .catch(err => console.error(err));
      } else {
        firebaseRef.push({
          status: 'bad', answers: antworten, title, type, verweis,
        }).then(key => console.log(`Neue Karte: ${key}`))
            .catch(err => console.error(err));
      }
    }
  });

  listenerPreview(); $('#addCard input').trigger('keyup');
  $('#addCard').show();
}

let abfrageArray = [];

function showAbfrage() {
  $('#liste').hide();
  $('#addCard').hide();

  abfrageArray = !data ? [] : Object.keys(data).map(key => {
    data[key].key = key;
    return data[key];
  }).sort(() => 0.5 - Math.random()).sort((a, b) => {
    let valA = 1;
    if (a.status == 'medium') valA = 2;
    if (a.status == 'bad') valA = 3;
    let valB = 1;
    if (b.status == 'medium') valB = 2;
    if (b.status == 'bad') valB = 3;
    return valB - valA;
  });

  displayFrage();
}

function displayFrage() {
  $('#abfrage').empty();
  if (abfrageArray.length > 0) {
    const frage = abfrageArray[0];

    let c = '#000';
    if (frage.status == 'perfect') c = '#79d375';
    if (frage.status == 'medium') c = '#f8d154';
    if (frage.status == 'bad') c = '#e9464a';

    const headerHtml = `<div class="listCard" cardid=${frage.key}><h5>${frage.type}
     ${frage.verweis || ''}</h5><span class="dot" style="background-color: ${c};"></span><h3>${frage.title}</h3></div>`;

    const ans = frage.answers.map(ans => converter.makeHtml(ans));

    const ansHtml = ans.map(ans => `<div class="listCard"><div class="ans" style="border: none;">${
      ans}</div><button type="button" class="showAns btn btn-outline-primary btn-lg btn-block" style="margin-bottom: 10px;">Anzeigen</button></div>`);

    const gewusstHtml = '<div class="listCard buttonCard" style="display: flex;"><button type="button" id="gewusst" class="btn btn-outline-success '
    + 'btn-lg btn-block">Gewusst</button><button type="button" id="nichtGewusst" class="btn btn-outline-danger btn-lg btn-block">'
    + 'Nicht gewusst</button><button type="button" id="überspringen" class="btn btn-outline-dark btn-lg btn-block">Überspringen</button></div>';

    $('#abfrage').append(headerHtml);
    $('#abfrage').append(ansHtml);
    $('#abfrage').append(gewusstHtml);

    $('#überspringen').unbind('click').on('click', () => {
      abfrageArray.shift();
      displayFrage();
    });
    $('#gewusst').unbind('click').on('click', () => {
      const stat = abfrageArray[0].status;
      if (stat == 'perfect') $('#überspringen').trigger('click');
      let newStat = 'perfect';
      if (stat == 'bad') newStat = 'medium';

      firebaseRef.child(abfrageArray[0].key).child('status').set(newStat);

      const frage = abfrageArray.shift();
      if (newStat != 'perfect') abfrageArray.push(frage);
      displayFrage();
    });
    $('#nichtGewusst').unbind('click').on('click', () => {
      const newStat = 'bad'; // always make it bad, if not known
      firebaseRef.child(abfrageArray[0].key).child('status').set(newStat);

      const frage = abfrageArray.shift();
      // get last bad index
      let index = 0;
      for (const fr of abfrageArray) {
        if (fr.status == 'bad') index += 1;
        else break;
      }
      abfrageArray.splice(index, 0, frage);
      displayFrage();
    });

    $('.ans').hide();
    $('.showAns').unbind('click').on('click', function () {
      $(this).siblings('.ans').show();
      $(this).hide();
    });
  } else {
    $('#abfrage').append('<div class="listCard"><h3>Keine Fragen verfügbar.</h3></div>');
  }
  reloadMathJax();
  showColorMode();
  $('#abfrage').show();
}

function showListe() {
  $('#abfrage').hide();
  $('#addCard').hide();

  $('#liste').empty();

  for (const key of Object.keys(data || {}).sort((aKey, bKey) => data[aKey].title.localeCompare(data[bKey].title))) {
    const card = data[key];
    let c = '#000';
    if (card.status == 'perfect') c = '#79d375';
    if (card.status == 'medium') c = '#f8d154';
    if (card.status == 'bad') c = '#e9464a';

    const answers = card.answers.map(ans => converter.makeHtml(ans));

    const html = getListCardHtml(
        key,
        card.title,
        card.type,
        card.verweis,
        c,
        answers,
    );

    $('#liste').append(html);
  }

  reloadMathJax();
  $('.ans').hide();

  $('.listHead')
      .unbind('click')
      .on('click', function () {
        $(this)
            .siblings('.answers')
            .children('.ans')
            .toggle();
      });

  $('.editCard').unbind('click').on('click', function () {
    editCard = $(this).closest('.listCard').attr('cardId');
    $('#hinzufuegenLink').click();
  });

  $('.dot')
      .unbind('click')
      .on('click', function () {
        const id = $(this)
            .closest('.listCard')
            .attr('cardId');
        const stat = data[id].status;
        let newStat = 'bad';
        if (stat == 'bad') newStat = 'medium';
        if (stat == 'medium') newStat = 'perfect';
        firebaseRef.child(`${id}/status`).set(newStat);
      });

  if (data == null || Object.keys(data).length == 0) {
    $('#liste').append('<div class="listCard"><h4>Noch sind keine Karten vorhanden.</h4></div>');
  }

  $('#liste').show();
}

function reloadMathJax() {
  if (typeof typeset === 'undefined' || typeset == true) {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub, $('.listCard').attr('id')]); // renders mathjax if 'typeset' is true (or undefined)
    typesetStubbornMath();
  }
}
// Render the bits of math that have inexplicably still failed to render, while
// leaving the rest alone. (If you try to typeset the whole page, it will break
// other things).
function typesetStubbornMath() {
  $('.MathJax_Preview').each(function () {
    if ($(this).text() != '') {
      MathJax.Hub.Queue(['Typeset', MathJax.Hub, $(this).attr('id')]);
    }
  });
}

function getListCardHtml(
    id = '',
    title = '',
    type = '',
    verweis = '',
    statusColor = '#000',
    answers = [],
    showEdit = true,
) {
  return `<div class="listCard" cardId="${id}">
    <div class="listHead">
      <h5>${type} ${verweis}</h5>
      <h3>${title}</h3>
    </div>
    <span class="dot" style="background-color: ${statusColor};"></span>
    <span class="editCard" ${!showEdit ? 'style="display: none;"' : ''}><img src="images/edit.svg" alt="edit"></span>
    <div class="answers">
      ${answers.map(ans => `<div class="ans">${ans}</div>`).join('')}
    </div>
  </div>`;
}

$('#abfrageLink, #listeLink, #hinzufuegenLink').on('click', function () {
  $('.navbar')
      .find('.active')
      .removeClass('active');

  $(this)
      .parents('.nav-item')
      .addClass('active');

  switch ($(this).attr('id')) {
    case 'abfrageLink':
      showListeAbfrage();
      break;
    case 'listeLink':
      showListeAbfrage();
      break;
    case 'hinzufuegenLink':
      showHinzufuegen();
      break;
    default:
      console.error(`unhandled case: ${$(this).attr('id')}`);
      break;
  }
});

function toogleDarkMode() { // eslint-disable-line no-unused-vars
  localStorage.setItem('mode', (localStorage.getItem('mode') || 'dark') === 'dark' ? 'light' : 'dark');
  showColorMode();
}

function showColorMode() {
  if (localStorage.getItem('mode') == null) localStorage.setItem('mode', 'dark');
  const manuellDark = 'html, .dot, #gewusst, #nichtGewusst, #überspringen, .listCard, .editCard, '
  + '#addAns, #saveCard, #deleteCard, code, #deleteSet, #neuesSet, #logoutLink';
  if (localStorage.getItem('mode') == 'light') $(manuellDark).removeClass('dark');
  else $(manuellDark).addClass('dark');
}

if (window.AmbientLightSensor) {
  const sensor = new AmbientLightSensor();
  sensor.addEventListener('reading', () => {
    const { illuminance } = sensor;
    if (illuminance < 20) localStorage.setItem('mode', 'dark');
    if (illuminance > 30) localStorage.setItem('mode', 'light');
    // console.log('Helligkeit: ' + illuminance);
    showColorMode();
  });
  sensor.addEventListener('error', error => {
    console.error(error);
  });
  sensor.start();
} else {
  console.log('Kein AmbientLightSensor verfügbar.');
  if (window.location.href.includes('light')) localStorage.setItem('mode', 'light');
  else if (window.location.href.includes('dark')) localStorage.setItem('mode', 'dark');
  showColorMode();
}

function escapeString(string) { // from https://github.com/joliss/js-string-escape/blob/master/index.js
  return (`${string}`).replace(/["'\\\n\r\u2028\u2029]/g, character => {
    switch (character) {
      // case '"':
      // case "'":
      case '\\':
        return `\\${character}`;
      case '\n':
        return '\n';
      case '\r':
        return '\\r';
      case '\u2028':
        return '\\u2028';
      case '\u2029':
        return '\\u2029';
      default:
        return character;
    }
  });
}
